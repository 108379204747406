.innovation__wrapper {
  width: 100%;
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  margin-bottom: 40px;
  margin-top: 60px;
  &__image-block {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 50px;
    margin-bottom: 45px;
    @media screen and (max-width: 1175px) {
      .img__left {
        display: none;
      }
      .img__right {
        display: none;
      }
    }
    .img__left {
    }
    .img__center {
      width: calc(90% - 250px);
      @media screen and (max-width: 991px) {
        width: 95%;
      }
    }
    .img__right {
    }
  }
  &__buttons-block {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    margin-bottom: 90px;
    &__title {
      width: 896px;
      text-align: center;
      font-weight: 500;
      font-size: 2.813rem;
      line-height: 67.5px;
      margin-bottom: 50px;
      @media screen and (min-width: 768px) and (max-width: 991px) {
        width: 580px;
        text-align: center;
        font-family: Poppins;
        font-size: 2.5rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      @media screen and (max-width: 767px) {
        font-size: 1.75rem;
        width: 100%;
      }
    }
    .title {
      @media screen and (max-width: 767px) {
        font-size: 1.75rem;
        padding: 0 20px;
      }
    }
    .buttons {
      display: flex;
      width: 100%;
      gap: 25px;
      flex-wrap: wrap;
      justify-content: center;
      button {
        max-width: 250px;
        padding: 16px 26px;
        font-weight: 500;
        width: 50%;
        text-align: center;
        min-height: 70px;

        @media screen and (max-width: 650px) {
          width: 100%;
        }
      }
      .button-real {
        color: var(--color-text);
        background: var(--color-success);
        font-size: 18px !important;

        @media screen and (max-width: 767px) {
          font-size: 16px;
        }
      }
      .button-demo {
        background: var(--color-text);
        color: var(--average-color);
        font-size: 18px !important;

        @media screen and (max-width: 767px) {
          font-size: 16px;
        }
      }
    }
  }
}
