.modal-form__input {
  position: relative;
  width: 100%;
  color: var(--color-text);

  &-label {
    position: absolute;
    top: -8px;
    left: 10px;
    background: var(--color-grey-2);
    color: var(--color-text);
    padding: 0 6px;
    line-height: 18px;
    z-index: 10;
  }
  &-value {
    width: 100%;
    height: 48px;
    background: 0 0;
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-sizing: border-box;
    border-radius: 5px;
    color: var(--color-text);
    padding: 8px 8px 8px 14px;
    font-size: 16px;
    line-height: 20px;
    outline: none;
  }
}

.customSelect {
  background-color: var(--color-select-grey-1) !important;
  border: 1px solid var(--color-grey-6);

  .ant-select-item-option-content {
    display: inline-flex;
    align-items: center;

    .item-img {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      background-color: #4fb97f;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      margin-right: 15px;
    }
    img {
      width: 25px;
      height: 25px;
      margin-right: 15px;
      border-radius: 50%;
    }
  }

  .ant-select-item-option-active:not(.ant-select-item-option-disabled),
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: var(--color-grey-dropdown);
    color: var(--color-text);
  }

  .ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: var(--color-grey-4);
    color: var(--color-text);
  }

  .ant-select-item {
    color: var(--color-text);
  }
}

.ant-select-single.ant-select-open .ant-select-selection-item {
  color: var(--color-text) !important;
}
