.backdrop {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  -webkit-transition:
    opacity 207ms ease-out,
    z-index 0s linear 315ms;
  -o-transition:
    opacity 207ms ease-out,
    z-index 0s linear 315ms;
  transition:
    opacity 207ms ease-out,
    z-index 0s linear 315ms;
  opacity: 0;
}

.sidebar.open + .backdrop {
  backdrop-filter: blur(4px);
  opacity: 1;
}
