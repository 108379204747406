.ant-collapse-content-box,
.ant-collapse-header-text {
  color: var(--color-text); /* Change the color value to the desired text color */
}

.anticon.anticon-right.ant-collapse-arrow {
  svg {
    fill: var(--color-text);
  }
}

.ant-collapse .ant-collapse-content {
  border: transparent;
}
