.svg-link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin-right: 16px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.05);
  &:hover {
    border-color: transparent;
    background-color: var(--color-link);
  }
  .social-IC {
    width: 16px;
    height: 16px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: contain;
    vertical-align: middle;
  }
}
