.modal-form__input {
  position: relative;
  width: 100%;
  color: var(--color-text);

  &-label {
    position: absolute;
    top: -8px;
    left: 10px;
    background: var(--color-grey-2);
    color: var(--color-text);
    padding: 0 6px;
    font-size: 16px;
    line-height: 18px;
    z-index: 10;
  }
  &-value {
    width: 100%;
    height: 48px;
    background: 0 0;
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-sizing: border-box;
    border-radius: 5px;
    color: var(--color-text);
    padding: 8px 8px 8px 14px;
    font-size: 16px;
    line-height: 20px;
    outline: none;
  }
}
.ant-input-disabled {
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
}
.ant-input-outlined[disabled],
.ant-select-outlined.ant-select-disabled:not(.ant-select-customize-input) .ant-select-selector {
  color: var(--color-grey-5) !important;
  border-color: var(--color-border-dark) !important;
}
