.modal-form__textarea {
  position: relative;
  width: 100%;
  margin: 0 auto 24px;
  color: var(--color-text);

  &-label {
    position: absolute;
    top: -8px;
    left: 10px;
    background: var(--color-grey-2);
    color: var(--color-text);
    padding: 0 6px;
    font-size: 14px;
    line-height: 18px;
    z-index: 10;
  }
  &-value {
    width: 100%;
    background: 0 0;
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-sizing: border-box;
    border-radius: 5px;
    color: var(--color-text);
    padding: 8px 8px 8px 14px !important;
    font-size: 16px;
    line-height: 20px;
    outline: none;
  }
}
