.nav-link {
  text-decoration: none;
  color: var(--color-text);

  svg {
    g {
      #Text {
        path {
          fill: var(--color-text);
        }
      }
    }
  }
}
