.faq-page-container {
  padding: 20px 10%;

  @media screen and (max-width: 1280px) {
    padding: 20px 70px;
  }

  @media screen and (max-width: 768px) {
    padding: 20px;
  }
}
.ant-collapse-item-active {
  background-color: var(--color-grey-2);
}
.faq-page {
  width: 100%;
  max-width: 1440px;
  margin: 0px auto;

  &__title {
    font-family: Poppins;
    font-size: 2.813rem;
    text-transform: uppercase;
    @media screen and (max-width: 1000px) {
      font-size: 1.75rem;
    }
  }
  .question-tab-items {
    margin-top: 22px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .questions__wrapper {
    margin-top: 57px;
    &__title {
      font-size: 2.188rem;
      font-weight: 500;
      padding-left: 30px;
      position: relative;
      margin-bottom: 20px;
      @media screen and (max-width: 1000px) {
        font-size: 1.375rem;
      }
      &::before {
        content: '';
        background: url('../../assets/icons/faq-ic.svg') no-repeat;
        background-size: 30px 30px;
        width: 35px;
        height: 35px;
        position: absolute;
        top: 10px;
        left: -5px;
        @media screen and (max-width: 1000px) {
          top: 0px;
        }
      }
    }
    &.verification {
      margin-bottom: 107px;
    }
  }
}
