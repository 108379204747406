.contact-us {
  width: 100%;
  max-width: 1440px;
  margin: 22px auto 0 auto;
  &__header__wrapper {
    margin-top: 22px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    @media screen and (max-width: 1024px) {
      flex-direction: column;
    }
  }
  &__text-wrapper {
    width: 887px;
    @media screen and (max-width: 1024px) {
      width: 100%;
      margin-bottom: 30px;
    }
    &__title {
      font-family: Poppins;
      font-size: 2.813rem;
      font-weight: 700;
      margin-bottom: 6px;
      @media screen and (min-width: 768px) and (max-width: 1024px) {
        font-size: 2.5rem;
      }
      @media screen and (max-width: 767px) {
        font-size: 1.75rem;
      }
    }
    &__subtitle {
      font-family: Poppins;
      font-size: 1.563rem;
      font-weight: 400;
      color: var(--color-text-secondary);
      @media screen and (min-width: 768px) and (max-width: 1024px) {
        font-size: 1.25rem;
      }
      @media screen and (max-width: 767px) {
        font-size: 16px;
      }
      &:first-of-type {
        margin-bottom: 32px;
      }
    }
  }
  &__address-wrapper {
    display: flex;
    flex: 1;
    justify-content: end;
    @media screen and (max-width: 1024px) {
      width: 100%;
    }
    .address-block {
      display: flex;
      flex-direction: column;
      max-width: 405px;
      background: var(--color-grey-2);
      border-radius: 20px;
      padding: 25px 18px;
      @media screen and (max-width: 1024px) {
        width: 100%;
        max-width: 100%;
      }
      &__text {
        font-size: 16px;
        display: flex;
        font-weight: 500;
        align-items: start;
        gap: 20px;
        margin-bottom: 30px;
        &__img {
          margin-top: 5px;
        }
        &__text2 {
          font-weight: 400;
        }
      }
      &__social {
        display: flex;
        align-items: center;
        padding-left: 55px;
      }
    }
  }
  &__topic-wrapper {
    margin-top: 88px;
    @media screen and (min-width: 768px) and (max-width: 1024px) {
      margin-top: 37px;
    }
    @media screen and (max-width: 767px) {
      margin-top: 22px;
    }
    &__title {
      font-size: 2.188rem;
      font-weight: 700;
      margin-bottom: 14px;
      @media screen and (min-width: 768px) and (max-width: 1024px) {
        font-size: 1.875rem;
      }
      @media screen and (max-width: 767px) {
        font-size: 1.375rem;
      }
    }
    &__buttons-block {
      display: flex;
      align-items: center;
      margin-bottom: 21px;

      gap: 25px;
      margin-top: 20px;
      .topic-button {
        width: 186px;
        height: 60px;
        background-color: var(--color-grey-2);
        color: var(--color-text-secondary);
        font-size: 14px !important;

        &.active {
          border: 1px solid var(--color-info);
          color: var(--color-text);
        }
      }
    }
    &__information-block {
      border-radius: 5px;
      background: var(--color-grey-2);
      max-width: 900px;
      padding: 20px 26px;
      margin-top: 35px;
      margin-bottom: 25px;
      @media screen and (max-width: 1024px) {
        width: 100%;
      }
    }
    .information__item {
      border: 1px solid var(--color-warning);
      border-radius: 5px;
      padding: 5px 10px 10px 50px;
      &:first-of-type {
        margin-bottom: 24px;
      }
      &__title {
        font-size: 1.125rem;
        font-weight: 500;
        margin-bottom: 7px;
        position: relative;
        &::before {
          content: '';
          background: url('../../assets/icons/warning.svg') no-repeat;
          width: 35px;
          height: 35px;
          position: absolute;
          top: 0;
          left: -35px;
        }
      }
      &__description {
        min-height: 88px;
        font-size: 16px;
        font-weight: 400;
      }
      &__points {
        list-style: none;
        li::before {
          content: '\2022';
          color: var(--color-text);
          border-radius: 50%;
          padding: 5px;
          margin-right: 5px;
        }
      }
    }
  }
  .open-form__button {
    width: 203px;
    margin-bottom: 21px;

    height: 60px;
    background-color: transparent;
    border: 2px solid var(--color-grey-2);

    color: var(--color-text-secondary);

    &.active {
      border: 1px solid var(--color-info);
      color: var(--color-text);
    }
  }
  &__contact-form {
    max-width: 900px;
    border-radius: 5px;
    background: var(--color-grey-2);
    padding: 10px 15px;
    padding-bottom: 37px;
    margin-bottom: 126px;
    @media screen and (max-width: 900px) {
      width: 100%;
    }
    @media screen and (min-width: 768px) and (max-width: 1024px) {
      margin-bottom: 75px;
    }
    @media screen and (max-width: 767px) {
      margin-bottom: 46px;
    }
    &__title {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 20px;
    }
    form {
      display: flex;
      flex-direction: column;
      gap: 21px;
      .input {
        background: transparent;
        border: 1px solid var(--color-grey-5);
        color: var(--color-text);
        &::placeholder {
          color: var(--color-grey-5);
        }
      }
      .contact-input {
        min-height: 45px;
      }
      .upload-input {
        margin-bottom: 10px;
      }
      .send-request__button {
        width: 250px;
        height: 70px;
        color: var(--color-text);
        background-color: var(--color-success);
        @media screen and (max-width: 767px) {
          width: 100%;
        }
      }
    }
  }

  &__space {
    margin-bottom: 89px;
  }
}
.formMessage {
  font-size: 16px;
  color: var(--color-text);
  margin-top: 10px;
}
.upload-text {
  color: var(--color-text);
  font-weight: 500;
}
.upload-hint {
  font-size: 0.875rem;
  color: var(--color-text);
  font-weight: 400;
}

.ant-upload-drag .ant-upload-btn {
  border: 1px dashed var(--color-link);
  background: rgba(22, 125, 212, 0.05);
}

.ant-form-horizontal .ant-form-item-control {
  flex: 1 1 0;
  margin: 0;
  min-width: 0;
}

.ant-upload-wrapper .ant-upload-list .ant-upload-list-item {
  position: relative;
  height: 22px;
  margin-top: 8px;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  transition: background-color 0.3s;
  color: var(--color-text);
}
.ant-upload-wrapper .ant-upload-list .ant-upload-list-item .ant-upload-icon .anticon {
  color: rgba(0, 0, 0, 0.45);
  font-size: 0.875rem;
  color: var(--color-text);
}
.ant-upload-wrapper .ant-upload-list .ant-upload-list-item .ant-upload-list-item-actions .anticon {
  color: rgba(0, 0, 0, 0.45);
  transition: all 0.3s;
  color: red;
}
