.app__sidebar {
  position: fixed;
  max-height: 100vh;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 100;
  min-width: 70px;
  background-color: var(--color-grey-1);
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 10px 0 20px;
  transition:
    width 207ms ease-out,
    min-width 207ms ease-out,
    padding 207ms ease-out;

  @media screen and (max-width: 768px) {
    padding: 20px 0 20px;
  }
}

.sidebar {
  &.open {
    min-width: 180px;
    padding: 74px 30px 15px;
    .sidebar__footer {
      display: none;
    }
    .sidebar__toggler {
      .open-sidebar {
        display: none;
      }
      .close-sidebar {
        display: block;
      }
    }
  }
  .sidebar__toggler {
    cursor: pointer;
    svg {
      display: flex;
      height: 20px;
      width: 20px;
      position: absolute;
      top: 20px;
      left: 24px;
      border: 0;
      color: inherit;
      cursor: pointer;
      text-transform: none;
    }
    .close-sidebar {
      display: none;
    }
  }
  &__navigations {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;

    .close_item {
      cursor: pointer;

      svg {
        g {
          fill: var(--color-text);
        }
      }
    }

    .nav-item {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      border-radius: 5px;
      width: 50px;
      height: 50px;
      font-size: 11px;
      text-transform: uppercase;

      svg {
        path {
          fill: var(--color-text);
        }
      }

      &.active {
        background: var(--color-success);

        svg {
          path {
            fill: #ffffff;
          }
        }
      }
    }
  }
  &__footer {
    width: 100%;
    margin-top: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    &__nav-item {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      max-width: 60px;
      width: 60px;
      min-height: 50px;
      border-radius: 5px;
      background: var(--color-grey-2);
      padding: 3px;
      font-size: 11px;
      text-transform: capitalize;
      word-break: break-all;
      text-align: center;

      svg {
        path {
          fill: var(--color-text);
        }
      }
    }
  }
}
