.header {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  padding: 15px 10px 5px 10px;
  flex-shrink: 0;

  @media screen and (max-width: 768px) {
    border-bottom: 1px solid var(--color-border-dark);
    padding: 10px 10px 5px 10px;
  }

  &__brand {
    display: flex;
    align-items: center;
    gap: 15px;
    cursor: pointer;

    @media screen and (max-width: 768px) {
      svg {
        height: 24px;
        width: 24px;

        path {
          stroke: var(--color-text);
        }
      }
    }

    &__slogan {
      color: var(--color-text);
      text-transform: uppercase;
      font-size: 1.125rem;
    }
  }
  &__container {
    display: flex;
    align-items: center;
  }
  &__usermenu {
    display: flex;
    align-items: center;

    .ant-btn-primary {
      min-width: 50px;
    }
  }

  &__navlinks {
    margin-left: 5px;
    display: flex;

    .moreButton {
      display: none;

      @media screen and (max-width: 768px) {
        display: block;
      }
    }

    .nav-link {
      margin: 0px 5px;

      @media screen and (max-width: 768px) {
        display: none;
      }
    }

    .header__navlink {
      display: flex;
      padding: 10px 12px;
      border-radius: 5px;
      font-size: 0.75rem;
      color: var(--color-text);
      gap: 10px;
    }
  }
}
.deposit_withdrawal {
  .ant-popover-inner-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

.user__account {
  .ant-popover-inner {
    padding: 0px;
  }
  .details__container {
    .trading__account {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .refresh-balance {
        float: right;
        color: var(--color-info);
        cursor: pointer;
      }
      svg {
        height: 20px;
        width: 20px;
      }
      .ant-radio-wrapper .ant-radio-inner {
        border-color: var(--color-text) !important;
      }
    }
    .ant-col {
      padding: 15px;
    }
    > .ant-col:first-child {
      border-right: 1px solid var(--color-border);
      padding: 0px;

      .ant-col:not(:last-child) {
        border-bottom: 1px solid var(--color-border);
      }

      @media screen and (max-width: 576px) {
        border-right: none;
        border-bottom: 1px solid var(--color-border);
      }
    }
  }
}
