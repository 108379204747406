.auth-page {
  max-width: 534px;
  margin: 0 auto;

  .login-user__btn {
    width: 100%;
    font-size: 16px;
    text-align: center;
    border-radius: 5px;
  }

  .title {
    font-size: 2.813rem !important;
    line-height: 67.5px;
    color: var(--color-text) !important;
    @media screen and (max-width: 850px) {
      font-size: 2.5rem !important;
      line-height: 48px;
    }

    @media screen and (max-width: 468px) {
      font-size: 1.75rem !important;
      line-height: 42px;
    }
  }

  &__title {
    text-align: center;
    margin: 45px 0;
    font-size: 24px;
    font-weight: 600;
  }
  &__form {
    width: 100%;
    border-radius: 20px;
    background: var(--color-grey-2);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 140px;
    &__page-indicator {
      border-radius: 5px;
      background: var(--color-grey-4);
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;
      padding: 5px 10px;
      margin-top: 25px;
      margin-bottom: 55px;
      @media screen and (max-width: 585px) {
        width: 90%;
      }
      a {
        padding: 5px 28px;
        text-align: center;
        border-radius: 5px;
        cursor: pointer;
        &.active {
          background: var(--color-grey-2);
        }
      }
    }
    &__title {
      text-align: center;
      font-weight: bold;
    }
    .form {
      max-width: 380px;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 8px;

      @media screen and (max-width: 520px) {
        padding: 0 20px;
      }

      .forgot-password__line,
      .agreement__line {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 30px;
        .remebmer-me {
          color: var(--color-text);
        }
        .forgot-password {
          text-transform: capitalize;
          color: var(--color-link);
        }
      }
      .agreement__line {
        flex-direction: column;
        align-items: start;
        gap: 6px;
        .checkbox-block {
          display: flex;
          align-items: flex-start;
          justify-content: center;
          .checkbox-label {
            margin-left: 8px;

            a {
              // margin-left: 2px;
              color: var(--color-link);
            }
          }
        }
      }
    }
  }

  .otp__timer {
    margin: 15px 0px;

    span {
      color: var(--color-text-error);
      min-width: 75px;
    }
  }

  &__otp {
    max-width: 380px;
    width: 100%;
    &__inputs-block {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      margin: 0px 0px 30px 0px;
    }
    .otp__input {
      width: 57px;
      height: 57px;
      background: transparent;
      border: 1px solid var(--color-grey-5);
      border-radius: 5px;
      text-align: center;
      font-size: 1.375rem;
      font-weight: bold;
      outline: none;
      color: var(--color-text);
      transition: color 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
      &:focus {
        color: var(--color-green);
      }

      @media screen and (max-width: 468px) {
        width: 45px;
        height: 45px;
      }
    }
  }
  .resend__otp-btn {
    margin: 30px 0px 20px 0px;
  }

  .otp__title,
  .card__container {
    margin: 55px 0;

    @media screen and (max-width: 468px) {
      margin: 30px 0;
    }

    .card__title {
      margin-bottom: 30px;
      text-align: center;
    }

    .card__link {
      color: var(--color-link);
      max-width: fit-content;
      display: block;
      margin: auto;
      height: auto;
    }

    .card__submit {
      margin-top: 10px;
    }
  }

  .sign-in-via__line {
    width: 100%;
    max-width: 380px;
    height: 1px;
    background: var(--color-grey);
    margin-bottom: 30px;
    position: relative;
    display: flex;
    justify-content: center;

    &::before {
      width: 130px;
      position: absolute;
      background: var(--color-grey-2);
      padding: 0 20px;
      top: -12px;
      left: calc(100% - 250px);
      @media screen and (max-width: 410px) {
        left: calc(100% - 220px);
      }
    }
  }
  .sign-via__buttons {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 44px;
    margin-left: 14%;
    .auth__btn {
      width: 100px;
      border: 1px solid var(--color-grey);
    }
  }
  .dropdown-currency {
    width: 100%;
    text-align: left;
    background: transparent;
    border: 1px solid var(--color-grey-5);
    color: var(--color-text);
    height: 50px;
    &::placeholder {
      color: var(--color-grey-5);
    }
  }
}

.validation-error__text {
  color: var(--color-red);
  text-align: center;
  margin: 10px 0px;
}

.kep-login-facebook {
  height: 16px;
  width: 32px;
}
