.home-page {
  width: 100%;
  max-width: 1440px;
  margin: 42px auto 0 auto;

  .video-section {
    width: 85%;

    @media screen and (max-width: 468px) {
      width: 95%;
    }
  }

  @media screen and (max-width: 767px) {
    margin: 50px auto 0 auto;
  }
  .demo-register__wrapper {
    display: flex;
    margin-bottom: 64px;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    .register-section {
      .text-section {
        @media screen and (max-width: 768px) {
          margin-top: 19px;
          margin-bottom: 52px;
          display: block;
          text-align: center;
          margin-bottom: 26px;
        }
      }
      .title {
        color: var(--color-text);
        font-size: 2.813rem !important;
        line-height: 67.5px;
        font-weight: 700;
        margin-top: 29.19px;
        @media screen and (max-width: 768px) {
          font-size: 2rem !important;
          line-height: 48px;
        }

        @media screen and (max-width: 468px) {
          font-size: 1.5rem !important;
          line-height: 42px;
        }
      }
      .subtitle {
        color: var(--color-text-secondary);
        font-size: 1.563rem;
        line-height: 37.5px;
        @media screen and (max-width: 768px) {
          color: #858585;
          text-align: center;
          font-family: Poppins;
          font-size: 1.25rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          width: 594px;
          flex-shrink: 0;
        }

        @media screen and (max-width: 480px) {
          margin-bottom: 14px;
          font-size: 1rem;
          line-height: 37.5px;
          font-weight: 400;
        }
      }
      .buttons-section {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 12px;
        margin-top: 17px;

        .link-btn {
          @media screen and (max-width: 468px) {
            width: 100%;
          }
        }

        @media screen and (max-width: 870px) {
          justify-content: center;
        }

        button {
          font-size: 18px;
          font-weight: 500;
          width: 100%;
          max-width: 240px;
          padding: 22px 24px;
          @media screen and (max-width: 570px) {
            max-width: 100%;
            padding: 8px;
          }
          &.register {
            width: 250px;
            height: 70px;
            border: 1px solid var(--color-success);
            background: var(--color-success);
            @media screen and (max-width: 468px) {
              margin-bottom: 4px;
              width: 100%;
              min-width: 180px;
              font-size: 14px;
              height: 40px;
            }
          }
          &.demo-register {
            width: 250px;
            height: 70px;
            border: 1px solid var(--color-text);
            background-color: var(--color-text);
            color: var(--color-black);

            @media screen and (max-width: 468px) {
              width: 100%;
              min-width: 180px;
              font-size: 14px;
              height: 40px;
            }
          }
        }
      }
    }
  }
  .brands__wrapper {
    text-align: center;
    @media screen and (min-width: 992px) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    width: 100%;
    margin: 8px auto 64px;
    @media screen and (min-width: 768px) and (max-width: 991px) {
      width: 668.813px;
      margin-top: 20px;
    }
    @media screen and (max-width: 767px) {
      margin: 20px auto 24.46px;
    }
    .icon {
      display: inline-block;
      width: 10px;
      height: 10px;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: contain;
      vertical-align: middle;
      position: relative;
      &__visa {
        width: 96px;
        height: 30px;
        background-image: url('../../assets/icons/visa.svg');
        @media screen and (min-width: 768px) and (max-width: 991px) {
          width: 142.805px;
          height: 46.217px;
        }
        @media screen and (max-width: 767px) {
          width: 81.778px;
          height: 26.466px;
        }
      }
      &__mastercard {
        width: 72px;
        height: 58px;
        background-image: url('../../assets/icons/mastercard-logo.svg');
        @media screen and (min-width: 768px) and (max-width: 991px) {
          width: 96.872px;
          height: 74.738px;
          margin-left: 114.2px;
        }
        @media screen and (max-width: 767px) {
          width: 55.474px;
          height: 42.799px;
          margin-left: 48.39px;
        }
      }
      &__advacash {
        width: 157px;
        height: 58px;
        background-image: url('../../assets/icons/advcash.svg');
        @media screen and (min-width: 768px) and (max-width: 991px) {
          width: 225.813px;
          height: 63px;
          margin-left: 89.13px;
        }
        @media screen and (max-width: 767px) {
          width: 129.313px;
          height: 36.077px;
          margin-left: 36.04px;
        }
      }
      &__perfect_money {
        width: 239px;
        height: 58px;
        background-image: url('../../assets/icons/perfect-money.svg');
        @media screen and (min-width: 768px) and (max-width: 991px) {
          width: 249px;
          height: 47px;
          margin-top: 96px;
          display: block;
          margin-left: auto;
          margin-right: auto;
        }
        @media screen and (max-width: 767px) {
          width: 142.592px;
          height: 26.915px;
          margin-top: 38.83px;
          display: block;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }
  .capability__wrapper {
    border-radius: 20px;
    background: var(--color-grey-2);
    padding: 20px;

    .capability-items {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      border-bottom: 1px solid rgba(255, 255, 255, 0.05);
      padding-bottom: 24px;
      @media screen and (max-width: 650px) {
        justify-content: center;
      }

      .capability-img-section {
        position: relative;

        .capability-icon__img {
          position: absolute;
          bottom: 6%;
          right: 4%;
        }
      }
    }
    .capability-item {
      position: relative;
      max-width: 250px;
      width: 100%;
      color: var(--color-text);
      margin-top: 37px;

      @media screen and (max-width: 650px) {
        position: relative;
        max-width: 100%;
        text-align: center;
      }

      &__images-wrapper {
        position: relative;
      }
      &__img {
        width: 100%;
        display: block;
        margin-bottom: 27px;
        border-radius: 10px;

        @media screen and (max-width: 650px) {
          margin: 0 auto 24px;
        }
        @media screen and (max-width: 767px) {
          margin: 0;
          width: 100%;
          margin-bottom: 12.43px;
        }
      }
      &__svg {
        position: absolute;
        top: 55%;
        right: 15px;
      }
      &__title {
        margin-bottom: 10px;
        font-weight: 600;
        font-size: 1.25rem;
        line-height: 24px;
        text-align: left;
      }
      &__text {
        font-size: 16px;
        line-height: 20px;
        color: rgba(255, 255, 255, 0.7);
        text-align: left;
        font-weight: 400;
      }
    }
    .capability-button__block {
      padding: 32px 0;
      .capability-button {
        max-width: 320px;
        width: calc(100% - 32px);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 24px 0;
        font-size: 18px;
        margin: 0 auto;
        background: var(--color-success);
        color: var(--color-text);
        border-radius: 10px;
        border: 0;
        @media screen and (max-width: 767px) {
          font-size: 16px;
          font-weight: 500;
        }
      }
    }
  }
  .video__wrapper {
    border: 1px solid red;
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    @media screen and (max-width: 450px) {
      padding: 0 15px;
    }
    .video-block {
      flex: 1;
      iframe {
        opacity: 0.8;
      }
    }
    .register-block {
      max-width: 390px;
      width: 100%;
      min-height: 440px;
      padding: 38px 16px;
      background: #353a4d;
      border-radius: 8px;
      &__icon {
        width: 110px;
        height: 110px;
        background: url('../../assets/images/power-img.png') no-repeat;
        background-size: 95px 95px;

        margin-bottom: 20px;
      }
      &__title {
        font-size: 2rem;
        font-weight: 700;
        line-height: 48px;
        margin-bottom: 20px;
      }
      &__subtitle {
        font-size: 1.563rem;
        font-weight: 400;
        line-height: 37px;
        margin-bottom: 23px;
      }
      &__button {
        width: 50%;
        background: var(--color-success);
        border-radius: 10px;
      }
    }
  }
  .mobile-app__wrapper {
    display: flex;
    margin-top: 45px;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-bottom: 40px;
    .image-block {
      img {
        width: 100%;
        width: 300px;
        @media screen and (max-width: 1200px) {
        }
      }
    }
    .get-app-block {
      width: 100%;
      max-width: 600px;
      @media screen and (max-width: 850px) {
        text-align: center;
      }
      &__title {
        color: var(--color-text);
        font-size: 2.813rem !important;
        line-height: 60px;

        @media screen and (max-width: 850px) {
          font-size: 2.5rem !important;
          line-height: 48px;
        }

        @media screen and (max-width: 468px) {
          font-size: 1.75rem !important;
          line-height: 42px;
        }
      }

      &__subtitle {
        color: var(--color-text-secondary);
        font-size: 1.563rem;
        line-height: 37.5px;
        @media screen and (max-width: 850px) {
          margin-bottom: 10px;
          font-size: 1.125rem;
          line-height: 31.5px;
        }
        @media screen and (min-width: 768px) and (max-width: 991px) {
          width: 624px;
          height: 113px;
          color: #858585;
          text-align: center;
          font-family: Poppins;
          font-size: 1.563rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
        @media screen and (max-width: 767px) {
          font-size: 1.25rem;
          font-weight: 400;
          padding: 0 19px 15px;
        }
        color: var(--color-text-secondary);
      }
      &__buttons {
        display: flex;
        margin-top: 28px;
        flex-wrap: wrap;
        gap: 15px;
        @media screen and (max-width: 850px) {
          justify-content: center;
        }
        @media screen and (min-width: 768px) and (max-width: 991px) {
          justify-content: center;
        }
        @media screen and (max-width: 500px) {
          flex-direction: column;
          gap: 10px;
          padding: 0 15px;
        }
        button {
          @media screen and (max-width: 500px) {
            width: 100%;
          }
        }
      }
    }
  }
  .start-trading__wrapper {
    margin: 58px auto 0;
    padding-bottom: 88px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
    text-align: center;
    @media screen and (max-width: 767px) {
      padding: 0;
    }
    &-title {
      font-size: 2.813rem;
      line-height: 67.5px;
      font-weight: 700;
      @media screen and (max-width: 767px) {
        font-size: 1.75rem;
        line-height: normal;
        padding: 0;
      }
    }
    &-subtitle {
      color: var(--color-text-secondary);
      font-size: 1.563rem;
      font-weight: 400;
    }
    &-steps {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 15px;
      @media screen and (max-width: 710px) {
        justify-content: center;
      }
      @media screen and (min-width: 768px) and (max-width: 991px) {
        justify-content: center;
      }
      .step {
        margin-top: 65px;

        a:hover {
          text-decoration: none;
          color: inherit;
        }

        @media screen and (min-width: 768px) and (max-width: 991px) {
          max-width: 768px;
        }
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        &-img {
          margin-bottom: 10px;
          border-radius: 10px;
          width: 100%;
          background: #1e2327;
          max-width: 425px;
          height: 263px;
          @media screen and (max-width: 767px) {
            width: 325px;
            height: 200.499px;
          }
        }
        &-title {
          font-size: 1.875rem;
          margin-bottom: 12px;
          font-weight: 500;
          @media screen and (min-width: 768px) and (max-width: 991px) {
            margin-top: 38.5px;
          }
          @media screen and (max-width: 767px) {
            margin-top: 17px;
            font-size: 1.75rem;
          }
        }
        &-description {
          font-size: 1.125rem;
          font-weight: 400;
          margin-bottom: 18px;
          @media screen and (min-width: 768px) and (max-width: 991px) {
            width: 571px;
            color: var(--color-text);
            text-align: center;
            font-family: Poppins;
            font-size: 1.563rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-bottom: 40px;
          }
          @media screen and (max-width: 767px) {
            padding: 0 32px;
          }
        }
        &-button {
          width: 100%;
          background: var(--color-success);
          font-size: 1.125rem;
          color: var(--color-text);
          min-height: 80px;
          padding: 0;

          @media screen and (max-width: 710px) {
            font-size: 0.875rem;
            min-height: 45px;
          }
          @media screen and (min-width: 768px) and (max-width: 991px) {
            margin-bottom: 81px;
          }
          @media screen and (max-width: 767px) {
            margin-bottom: 50px;
            font-size: 16px;
            font-weight: 500;
            padding: 24px;
          }
        }
      }
    }
  }
  .faq__wrapper {
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
    margin-bottom: 110px;
    margin-top: 90px;
    &-title {
      margin-bottom: 10px;
      @media screen and (max-width: 1200px) {
        text-align: center;
        margin-bottom: 35px;
      }
      @media screen and (max-width: 767px) {
        font-size: 1.75rem;
        font-weight: 700;
        padding: 0 15px;
        margin-top: 43px;
        margin-bottom: 0px;
      }
    }
    .faq-item__question {
      @media screen and (max-width: 767px) {
        font-size: 16px;
        font-weight: 400;
      }
    }
    .faq-answer__container {
      display: flex;
      gap: 24px;
      margin-bottom: 18px;
      @media screen and (max-width: 1025px) {
        display: block;
      }
    }

    .allquestion-link {
      display: flex;
      max-width: 388px;
      padding: 20px 0;
      font-weight: bold;
      background-color: transparent;
      border: 2px solid var(--color-border-dark);
      color: var(--color-link);
      align-items: center;
      justify-content: center;
      margin: 70px auto;
      border-radius: 10px;
      @media screen and (max-width: 767px) {
        margin-left: 20px;
        margin-right: 20px;
        max-width: 375px;
      }
    }
  }
}
