.tab-item {
  display: flex;
  align-items: center;
  border-radius: 14px;
  background-color: var(--color-grey-1);
  width: calc(33% - 20px);
  padding: 15px;
  gap: 25px;
  @media screen and (max-width: 1100px) {
    width: 100%;
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  .tab-item__text-wrapper {
    width: 90%;
    &__title {
      font-family: Poppins;
      font-size: 1.25rem;
    }
    &__subtitle {
      font-family: Poppins;
      font-size: 16px;
    }
  }
}

html {
  scroll-behavior: smooth;
}
